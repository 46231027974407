<template>
  <div class="article-front">
    <router-link :to="{ name: 'Article',  params: {id : article.id} }" v-if="!loader">
      <img
        :src="image"
        class="img-fluid"
        :title="article.title.rendered"
        :alt="article.title.rendered"
      />
    </router-link>
    <Loader class="loader-little" v-else /><br />
    <router-link
      :to="{ name: 'Article',  params: {id : article.id} }"
      v-html="article.title.rendered"
    />
  </div>
</template>

<script>
export default {
  components: {
    Loader: () => import("../components/Loader.vue"),
  },
  name: "ArticleFront",
  props: ["article"],
  data() {
    return {
      loader: true,
    };
  },
  mounted() {
    this.$http
      .get(
        "https://api.opci-ethnodoc.fr/wp-json/wp/v2/media/" +
          this.article.featured_media
      )
      .then(
        (response) => (
          (this.image = response.data.guid.rendered), (this.loader = false)
        )
      );
  },
};
</script>

<style>
.article-front {
  border: solid 3px #68bec5;
  background-color: #68bec5;
  padding: 5px;
  margin-bottom: 15px;
}

.article-front img {
  width: 30vw;
  margin-bottom: 15px;
}

.article-front p {
  font-style: italic;
}

.loader.little {
  width: 5vh;
  height: 5vh;
}
</style>